import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios/middleware";
import { cleanImageUrl } from "../functions";

export const fetchExtraTypes = createAsyncThunk(
    "extraTypes/fetchExtraTypes",
    async (fetchFlagURL) => {
        if (fetchFlagURL) {
            try {
                const response = await axios.get("/stena/data/all.json");
                const extraTypes = response.data.data;
                const { Badge, Shop, Medal } = extraTypes;
                const newBadge = Badge.map((item) => {
                    const clearedImage = cleanImageUrl(item.image);
                    return { ...item, image: clearedImage };
                });
                const newShop = Shop.map((item) => {
                    const clearedImage = cleanImageUrl(item.image);
                    return { ...item, image: clearedImage };
                });
                const newMedal = Medal.map((item) => {
                    const clearedImage = cleanImageUrl(item.image);
                    return { ...item, image: clearedImage };
                });
                return { Badge: newBadge, Shop: newShop, Medal: newMedal };
                // return extraTypes;
            } catch (error) {
                throw error;
            }
        }
    }
);

const extraTypesSlice = createSlice({
    name: "extraTypes",
    initialState: {
        extraTypes: [],
        extraCommentsForUser: "",
        extraCommentsForAdmin: "",
        loading: false,
        error: null,
    },
    reducers: {
        setExtraCommentsForUser: (state, action) => {
            state.extraCommentsForUser = action.payload;
        },
        setExtraCommentsForAdmin: (state, action) => {
            state.extraCommentsForAdmin = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchExtraTypes.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchExtraTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.extraTypes = action.payload;
            })
            .addCase(fetchExtraTypes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
export const { setExtraCommentsForUser, setExtraCommentsForAdmin } =
    extraTypesSlice.actions;

export default extraTypesSlice.reducer;
