import React, { useState, useEffect } from "react";
import Pusher from "pusher-js";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrencyUpdateHandler from "./CurrencyUpdateHandler";
import DynamicStatsHandler from "./DynamicStatsHandler";
import MenuBadgeHandler from "./MenuBadgeHandler";
import LikeEventHandler from "./LikeEventHandler";
import NotificationsListUpdate from "./NotificationsListUpdate";
import ToastEventHandler from "./ToastEventHandler";
import StaticEventHandler from "./StaticEventHandler";
// import UserActivityTracker from "./UserActivityTracker";
import PushActive from "./PushActive";
import { useDispatch, useSelector } from "react-redux";
import {
    addComment,
    addLike,
    removeLike,
    addStena,
} from "../../redux/stenasSlice";
import GratitudeLeaders from "./GratitudeLeaders";

function WebSocketComponent() {
    const [notificationData, setNotificationData] = useState(null);
    const [authUserId, setAuthUserId] = useState(
        document.getElementById("Authdatausers")
            ? document
                  .getElementById("Authdatausers")
                  .getAttribute("data-user") * 1
            : null
    );
    const [AuthUserIdgetUserDialogIds, setAuthUserIdgetUserDialogIds] =
        useState(null);
    const dispatch = useDispatch();
    const { Shop, Badge, Medal } = useSelector(
        (state) => state.extraTypes.extraTypes
    );

    useEffect(() => {
        Pusher.logToConsole = false;
        const pusher = new Pusher("AnyKeyYouLike", {
            cluster: "eu",
            wsHost: window.location.hostname,
            wsPort: 6001,
            forceTLS: false,
            disableStats: true,
            enabledTransports: ["ws", "wss"],
        });

        if (!authUserId) {
            fetch("/get-current-user-id")
                .then((response) => response.json())
                .then((datauser) => {
                    setAuthUserId(datauser.userId); // Обновляем состояние компонента
                });
        }

        //Определяем личный канал юзера
        const UserChannel = pusher.subscribe("UserChannel." + authUserId);

        // личные евенты для authUserId
        if (AuthUserIdgetUserDialogIds === null) {
            fetch("/chat/getUserDialogIds")
                .then((response) => response.json())
                .then((data) => {
                    setAuthUserIdgetUserDialogIds(true); // Обновляем состояние компонента
                    // Subscribe to channels for each dialog ID
                    data.dialogIds.forEach((dialogId) => {
                        const UserChannelChat = pusher.subscribe(
                            "ChatEvent." + dialogId
                        );

                        UserChannelChat.bind(
                            "App\\Events\\ChatEvent",
                            function (event) {
                                // console.log(event);
                                if (event.type === "newDialog") {
                                    // Handle newDialog event
                                } else if (event.type === "newMessage") {
                                    // Handle newMessage event
                                    //  console.log("newMessage");
                                } else if (event.type === "typing") {
                                    // Handle typing event
                                }
                            }
                        );
                    });
                })
                .catch((error) => {
                    console.error("Error fetching dialog IDs:", error);
                });
        }

        UserChannel.bind(
            "App\\Events\\PrivateCurrencyUpdateEvent",
            function (data) {
                if (data.id === authUserId) {
                    CurrencyUpdateHandler({ data }); // валюта  //   medal.meow и medal.redik
                }
            }
        );

        UserChannel.bind(
            "App\\Events\\PrivateUserNewCommentEvent",
            function (data) {
                if (data.comment.author_id != authUserId) {
                    //  alert("Received a new comment: " + data.comment.id);
                }
            }
        );

        // личные евенты для authUserId

        // общие евенты
        const channel = pusher.subscribe("home");

        channel.bind("App\\Events\\NewMedalUserEvent", (data) => {
            if (data.user.id === authUserId) {
                //   ToastEventHandler({ data });
                CurrencyUpdateHandler({ data }); // валюта  //   medal.meow и medal.redik
            }
        });

        channel.bind("App\\Events\\GratitudeLeaders", (data) => {
            const GratitudeLeadersElement =
                document.getElementById("gratitudeLeaders");
            if (GratitudeLeadersElement) {
                GratitudeLeaders({ data });
            }
        });

        channel.bind("App\\Events\\StaticOnline", (data) => {
            StaticEventHandler({ data });
        });

        channel.bind("App\\Events\\PollEvent", (data) => {
            /// ну например. если автор был на двух страницах то на второй должен добавиться блок
            existingElement = document.getElementById(`poll_${data.userid}`);
            existingElementUserid = document.getElementById(
                `poll_${data.userid}_${data.poll.id}`
            );
            if (existingElement) {
                if (!existingElementUserid) {
                    existingElement.innerHTML = `
        <h2>${data.title}</h2>
        <p>Created: ${data.poll.id}</p>
        <p>Created: ${data.poll.created_at}</p>
        <p>Author: ${data.poll.creator_id}</p>
        <p>Anonymous: ${poll.poll.is_anonymous ? "Да" : "Нет"}</p>
      `;
                    document.querySelector(".container").appendChild(pollBlock);
                }
            }
        });

        channel.bind("App\\Events\\LikeEvent", (data) => {
            LikeEventHandler({ data });
            if (data.action === "add") {
                dispatch(addLike({ data }));
                if (data.author === authUserId && data.author !== data.userid) {
                    ToastEventHandler({ data });
                }
            } else if (data.action === "remove") {
                dispatch(removeLike({ data }));
            }
        });

        channel.bind("App\\Events\\NewCommentEvent", (data) => {
            // CommentEventHandler({ data }); // комментарий
            dispatch(addComment({ data }));
        });

        channel.bind("App\\Events\\NewStenaEvent_Fignja", (data) => {
            console.log("дата после отработки сокета", data);

            // Получаем значение data-user с элемента страницы
            const stenaDivsdispatch = document.getElementById("stena");
            const userIdFromBladedispatch = stenaDivsdispatch
                ? stenaDivsdispatch.getAttribute("data-user")
                : null;
            const userIdFromSocket = data.stena.user_id_wall_post;

            // Преобразуем значения для безопасного сравнения
            const userIdFromBladedispatchNumber = Number(
                userIdFromBladedispatch
            );
            const userIdFromSocketNumber = Number(userIdFromSocket);

            // Если у нас есть оба значения и нужно их сравнить
            if (
                userIdFromSocket !== undefined &&
                userIdFromBladedispatch !== null
            ) {
                // Сравниваем как числа
                if (userIdFromSocketNumber !== userIdFromBladedispatchNumber) {
                    console.log(
                        "user_id_wall_post не совпадает с data-user на странице (по числовому значению), не отправляем данные."
                    );
                    return; // Прерываем выполнение, если не совпадает
                } else {
                    console.log(
                        "user_id_wall_post совпадает с data-user, отправляем данные на личную стену.",
                        userIdFromSocketNumber
                    );
                }
            } else if (!userIdFromSocket && !userIdFromBladedispatch) {
                console.log(
                    "Нет привязки к пользователю, отправляем данные на общую стену."
                );
            } else {
                console.log("Не подходит условие для отправки события.");
                return; // Прерываем выполнение, если не соблюдаются условия
            }

            // Проверяем наличие транзакции с shops_id, badges_id или medal_id
            if (data?.stena?.transaction) {
                // Если транзакция с shops_id
                if (data?.stena?.transaction?.shops_id && Shop.length) {
                    const shop = Shop.find(
                        (item) => item.id === data.stena.transaction.shops_id
                    );
                    const newData = {
                        ...data,
                        stena: {
                            ...data.stena,
                            transaction: { ...data.stena.transaction, shop },
                        },
                    };
                    dispatch(addStena({ data: newData }));
                }
                // Если транзакция с badges_id
                else if (data?.stena?.transaction?.badges_id && Badge.length) {
                    const badge = Badge.find(
                        (item) => item.id === data.stena.transaction.badges_id
                    );
                    const newData = {
                        ...data,
                        stena: {
                            ...data.stena,
                            transaction: { ...data.stena.transaction, badge },
                        },
                    };
                    dispatch(addStena({ data: newData }));
                }
                // Если транзакция с medal_id
                else if (data?.stena?.transaction?.medal_id && Medal.length) {
                    const medal = Medal.find(
                        (item) => item.id === data.stena.transaction.medal_id
                    );
                    const newData = {
                        ...data,
                        stena: {
                            ...data.stena,
                            transaction: { ...data.stena.transaction, medal },
                        },
                    };
                    dispatch(addStena({ data: newData }));
                }
            } else {
                // Если транзакции нет, просто отправляем данные на нужную стену
                if (userIdFromBladedispatch) {
                    // Личная стена, если существует userIdFromBladedispatch
                    console.log(
                        "Личная стена, отправляем данные с учетом user_id_wall_post:",
                        userIdFromBladedispatch
                    );
                    dispatch(
                        addStena({
                            data: {
                                ...data,
                                stena: {
                                    ...data.stena,
                                    user_id_wall_post: userIdFromBladedispatch,
                                },
                            },
                        })
                    );
                } else {
                    // Общая стена, если нет userIdFromBladedispatch
                    console.log(
                        "Общая стена, отправляем данные без изменений."
                    );
                    dispatch(addStena({ data }));
                }
            }
        });

        channel.bind("App\\Events\\NewStenaEvent", (data) => {
            console.log("дата после отработки сокета", data);
            /// если есть на странице data-user то нужно учесть что бы не пушить там где есть другой юзер
            //user_id_wall_post спросить

            const stenaDivsdispatch = document.getElementById("stena");
            const userIdFromBladedispatch = stenaDivsdispatch
                ? stenaDivsdispatch.getAttribute("data-user")
                : null;

            if (data.stena.user_id_wall_post && userIdFromBladedispatch) {
                console.log(
                    "user_id_wall_post есть! Значит отправляем только на стену с таким data-user",
                    data.stena.user_id_wall_post
                );
            } else {
                console.log(
                    "user_id_wall_post нет! Значит отправляем только на стену где пустой data-user"
                );
            }

            if (data?.stena?.transaction?.shops_id && Shop.length) {
                const shop = Shop.find(
                    (item) => item.id === data.stena.transaction.shops_id
                );
                const newData = {
                    ...data,
                    stena: {
                        ...data.stena,
                        transaction: { ...data.stena.transaction, shop },
                    },
                };
                dispatch(addStena({ data: newData }));
            } else if (data?.stena?.transaction?.badges_id && Badge.length) {
                const badge = Badge.find(
                    (item) => item.id === data.stena.transaction.badges_id
                );
                const newData = {
                    ...data,
                    stena: {
                        ...data.stena,
                        transaction: { ...data.stena.transaction, badge },
                    },
                };

                dispatch(addStena({ data: newData }));
            } else if (data?.stena?.transaction?.medal_id && Medal.length) {
                const medal = Medal.find(
                    (item) => item.id === data.stena.transaction.medal_id
                );
                const newData = {
                    ...data,
                    stena: {
                        ...data.stena,
                        transaction: { ...data.stena.transaction, medal },
                    },
                };

                dispatch(addStena({ data: newData }));
            } else {
                dispatch(addStena({ data }));
            }
        });

        channel.bind("App\\Events\\CurrencyUpdateEvent", (data) => {
            CurrencyUpdateHandler({ data }); // валюта
        });

        channel.bind("App\\Events\\DailyStatsUpdateEvent", (data) => {
            DynamicStatsHandler({ data }); // статистика за сегодня
        });

        channel.bind("App\\Events\\MenuUpdateEvent", (data) => {
            MenuBadgeHandler({ data }); // банжи меню
        });
        channel.bind("App\\Events\\NotificationsListUpdate", (data) => {
            NotificationsListUpdate({ data }); // лента уведомлений
        });

        channel.bind("App\\Events\\NotificationEvent", (data) => {
            if (data.user.id === authUserId) {
                setNotificationData(data); // Обновляем данные уведомлений
            }
        });

        // Подписка на канал
        channel.bind("App\\Events\\NotificationEvent", (data) => {
            if (data.user.id === authUserId) {
                // Добавляем новое уведомление
                addNotification({
                    id: data.id, // ID уведомления
                    message: data.message, // Текст уведомления
                    subject: data.subject, // Тема уведомления
                    svg: data.svg, // svg
                    created_at: "только что", // Динамическое значение времени
                    link: data.link, // link
                    sender: {
                        name: data.user.id, // ид отправителя
                        names: data.user.name, // Имя отправителя
                        short_name: data.user.short_name, // Краткое имя отправителя
                        avatar: data.user.avatar, // URL аватара
                        color: data.user.color, // Цвет аватара (если нет изображения)
                    },
                });
            }
        });

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, [Shop]);

    return (
        <div>
            {/* Отображаем UserActivityTracker */}
            {/* <UserActivityTracker /> */}
            <ToastContainer />
            {/* Отображаем PushActive, передавая данные через props */}
            {notificationData && <PushActive data={notificationData} />}
        </div>
    );
}

export default WebSocketComponent;
