// MBTI.js
import React from "react";
import OpenInNewWindowSVG from "./Icons/OpenInNewWindowSVG";

const MBTI = ({ type }) => {
    const MBTI_TYPES = {
        INTJ: {
            title: "Стратег",
            name: "INTJ-A / INTJ-T",
            description:
                "На вершине одиноко. Стратеги это хорошо знают — это один из самых редких и стратегически одаренных типов личностей. Стратеги составляют всего два процента населения, а женщины с таким типом личности — это особенная редкость — всего 0,8 % населения. Им часто тяжело найти единомышленников, которые смогут поддержать их неустанный интеллект и их мышление шахматиста. Люди с типом личности Стратега одарены богатым воображением и одновременно решительны, амбициозны, но скрытны, чрезвычайно любопытны, но не тратят попусту свою энергию.",
            link: "https://www.16personalities.com/ru/lichnost-intj",
            image: "/img/INTJ.svg",
        },
        INFJ: {
            title: "Активист",
            name: "INFJ-A / INFJ-T",
            description:
                "Активист — очень редкий тип личности. Активисты составляют менее одного процента населения, но оставляют яркий след в мире. У них врожденное чувство идеализма и моральности, а от других идеалистов их отличает решительность и целеустремленность. Активисты — это не ленивые мечтатели, а люди, которые способны предпринимать конкретные шаги для достижения своих целей и выполнения долгосрочной стратегии.",
            link: "https://www.16personalities.com/ru/lichnost-infj",
            image: "/img/INFJ.svg",
        },
        ISTJ: {
            title: "Администратор",
            name: "ISTJ-A / ISTJ-T",
            description:
                "Тип личности Администратор считается одним из наиболее распространенных и составляет 13% населения. Определяющие характеристики данного типа — целостность, практическая логика и неустанное следование своему долгу — делают Администраторов жизненно важным ядром многих семей и организаций, в которых ценятся традиции, правила и стандарты, например, в юридических компаниях, правительственных организациях или в армии. Люди типа личности Администратор предпочитают отвечать за свои поступки и гордиться работой, которую они выполняют — когда они работают над поставленной задачей, они не жалеют ни сил, ни энергии, выполняя задания аккуратно и терпеливо.",
            link: "https://www.16personalities.com/ru/lichnost-istj",
            image: "/img/ISTJ.svg",
        },
        ISTP: {
            title: "Виртуоз",
            name: "ISTP-A / ISTP-T",
            description:
                "Виртуозы любят пробовать новое на ощупь и на глаз, трогая и изучая мир вокруг себя со спокойным рационализмом и воодушевленным любопытством. Люди с таким типом личности — прирожденные творцы, двигающиеся от проекта к проекту, строя полезное и бесполезное ради самого процесса и приобретая новый опыт по мере продвижения. Часто они бывают механиками или инженерами: для Виртуозов нет большего удовольствия, чем возиться по локоть в механизмах, разбирая и собирая их вновь, делая их чуть лучше, чем они были до этого.",
            link: "https://www.16personalities.com/ru/lichnost-istp",
            image: "/img/ISTP.svg",
        },
        INTP: {
            title: "Ученый",
            name: "INTP-A / INTP-T",
            description:
                "Тип личности «Ученый» встречается довольно редко и составляет лишь три процента населения: это очень хорошо, ибо нет для таких людей нет большей неприятности, чем быть «как все». Ученые гордятся своей изобретательностью и творческой натурой, уникальным видением и живым интеллектом. Такой человек может быть известным философом, архитектором или эксцентричным профессором. Ученым принадлежат многие научные открытия в нашей истории.",
            link: "https://www.16personalities.com/ru/lichnost-intp",
            image: "/img/INTP.svg",
        },
        INFP: {
            title: "Посредник",
            name: "INFP-A / INFP-T",
            description:
                "Посредники — истинные идеалисты, всегда ищущие искру добра даже в худших из людей или событий в попытке улучшить ситуацию. В то время, как многие воспринимают их как спокойных, сдержанных, даже стеснительных людей, в Посредниках горит внутреннее пламя и страсть, которые могут в какой-то момент засиять. Составляя всего 4% от населения, личности типа «Посредник» часто рискуют остаться непонятыми — но, когда они находят думающих схожим образом людей, с которыми они проводят время, появившаяся гармония становится источником радости и вдохновения.",
            link: "https://www.16personalities.com/ru/lichnost-infp",
            image: "/img/INFP.svg",
        },
        ISFJ: {
            title: "Защитник",
            name: "ISFJ-A / ISFJ-T ",
            description:
                "Тип личности Защитник весьма уникален, поскольку многие из их способностей противоречат их индивидуальным чертам. Чуткие Защитники могут прийти в ярость, если необходимо защитить свою семью или друзей; спокойные и скрытные, они, как правило, обладают хорошо развитыми навыками общения с людьми и крепких социальных взаимоотношений; и хотя они стремятся к безопасности и стабильности, такие типы личности могут быть весьма открытыми к изменениям, если чувствуют, что их понимают и уважают. Как и большинство подобных явлений, люди типа Защитник есть нечто большее, чем сумма их частей, и то, как они используют свои сильные стороны, определяет их личность.",
            link: "https://www.16personalities.com/ru/lichnost-isfj",
            image: "/img/ISFJ.svg",
        },
        ISFP: {
            title: "Артист",
            name: "ISFP-A / ISFP-T ",
            description:
                "Артисты являются настоящими творческими личностями, но не обязательно в стандартном значении этого слова, подразумевающем, что они рисуют маленьких счастливых пони. Да, такое бывает нередко, они прекрасно это могут. Скорее это означает, что они своим пониманием эстетики и дизайна и собственными действиями раздвигают границы общепризнанных норм. Артисты любят разрушать традиционные ожидания экспериментами с внешним видом и поведением. Вероятно, у них голове постоянно звучит: «Не сажайте меня в клетку!»",
            link: "https://www.16personalities.com/ru/lichnost-isfp",
            image: "/img/ISFP.svg",
        },
        ENTJ: {
            title: "Командир",
            name: "ENTJ-A / ENTJ-T ",
            description:
                "Командиры — это прирожденные лидеры. Люди с этим типом личности объединяют в себе харизму и уверенность, они обладают авторитетом, который собирает толпы вокруг одной общей цели. В отличие от более чувствительных Протагонистов Командиры характеризуются часто безжалостным уровнем рациональности и направляют свою энергию, уверенность и острый ум на достижение запланированных целей. Вероятно, хорошо, что такие люди составляют всего три процента населения, не затмевая собой более робких и чувствительных типов личности, которые составляют остальное население мира, однако нам следует поблагодарить Командиров за многие компании и учреждения, услугами которых мы пользуемся каждый день.",
            link: "https://www.16personalities.com/ru/lichnost-ENTJ",
            image: "/img/ENTJ.svg",
        },
        ENFJ: {
            title: "Тренер",
            name: "ENFJ-A / ENFJ-T ",
            description:
                "Тренеры являются природными лидерами, полными страсти и харизмы. Составляя всего около двух процентов населения, эти люди встречаются среди наших политиков, наших тренеров, наших наставников, они распространяют влияние и вдохновляют остальных на достижения во имя улучшения мира. Тренеры от природы уверены в себе и оказывают влияние на людей, они с гордостью и радостью демонстрируют другим, как улучшить самого себя и мир вокруг.",
            link: "https://www.16personalities.com/ru/lichnost-ENFJ",
            image: "/img/ENFJ.svg",
        },
        ESTJ: {
            title: "Менеджер",
            name: "ESTJ-A / ESTJ-T ",
            description:
                "Менеджеры представляют закон и порядок, они применяют свое понимание того, что правильно и неправильно, что приемлемо в обществе, а что нет. Они скрепляют семьи и сообщества. Твердо веря в честность, прилежность и порядочность, людей с типом личности Менеджера ценят за четкие советы и разумные инструкции, и они с удовольствием ведут за собой через сложные препятствия. Гордясь своей способностью объединять людей, Менеджеры часто становятся общественными организаторами, упорно работающими, собирающими людей на важные общественные праздники либо на защиту традиционных ценностей, скрепляющих семьи и общество.",
            link: "https://www.16personalities.com/ru/lichnost-ESTJ",
            image: "/img/ESTJ.svg",
        },
        ESTP: {
            title: "Делец",
            name: "ESTP-A / ESTP-T ",
            description:
                "Тип личности Делец всегда оказывает влияние на свое непосредственное окружение; лучший способ обнаружить их на вечеринке — это следить за водоворотом людей, следующим за кем-то, кто перемещается от группы к группе. Смешливые и артистичные, с грубоватым и житейским чувством юмора, Дельцы любят быть центром внимания. Если просят кого-то из публики выйти на сцену, Делец вызовется первым или подтолкнет застенчивого друга. Теория, абстрактные концепции и тяжеловесные дискуссии о глобальных проблемах и их последствиях не заинтересуют Дельцов надолго. Эти люди любят живой разговор с определенной толикой интеллектуальности, но о том, что происходит прямо сейчас, а еще лучше вместо разговора пойти и заняться делом. Дельцы прыгают, не посмотрев вниз, исправляют свои ошибки по пути, а не сидят без движения, готовя запасы на случай нехватки и разрабатывая пути возможного отступления.",
            link: "https://www.16personalities.com/ru/lichnost-ESTP",
            image: "/img/ESTP.svg",
        },
        ENTP: {
            title: "Полемист",
            name: "ENTP-A / ENTP-T ",
            description:
                "Полемист — величайший адвокат дьявола, который успешно разрушает все аргументы и мнения на мелкие составляющие, пуская их по ветру, чтобы все видели. В отличие от более целеустремленных личностей Полемисты делают это все не потому, что пытаются достичь какой-то стратегической задачи или глубинной цели, а просто для удовольствия. Никто так не обожает процесс ментального поединка, как Полемисты, поскольку это дает им возможность потренировать свой исключительно острый ум, широкую базу познаний и способность объединять разрозненные идеи в доказательства своей правоты.",
            link: "https://www.16personalities.com/ru/lichnost-ENTP",
            image: "/img/ENTP.svg",
        },
        ENFP: {
            title: "Борец",
            name: "ENFP-A / ENFP-T ",
            description:
                "Борец — настоящий свободный дух. Борцы часто являются заводилами на вечеринках, однако они не сильно заинтересованы в шумихе и не склонны к наслаждению моментом, они наслаждаются социальными и эмоциональными контактами с другими. Очаровательные, независимые, энергичные и сострадательные — те 7 % населения, которые они составляют, можно, безусловно, почувствовать в любой толпе.",
            link: "https://www.16personalities.com/ru/lichnost-ENFP",
            image: "/img/ENFP.svg",
        },
        ESFJ: {
            title: "Консул",
            name: "ESFJ-A / ESFJ-T ",
            description:
                "Люди, относящиеся к типу личности «Консул», являются, за неимением более подходящего слова, популярными, ведь такой тип личности составляет двенадцать процентов населения. В старших классах Консулы — это чирлидеры и квотербэки, которые задают тон, находятся в центре внимания и ведут свои команды к победе и славе. Позже по жизни Консулы продолжают пользоваться поддержкой своих друзей и любимых, организовывая социальные встречи и делая все возможное для того, чтобы убедиться, что все счастливы.",
            link: "https://www.16personalities.com/ru/lichnost-ESFJ",
            image: "/img/ESFJ.svg",
        },
        ESFP: {
            title: "Развлекатель",
            name: "ESFP-A / ESFP-T ",
            description:
                "Если кто-то и может спонтанно пуститься в пляс и запеть, так это тип личности Развлекатель. Развлекатели живут восторгом момента, и хотят, чтобы и все остальные чувствовали себя так же. Никакой другой тип личности не отдает настолько щедро свое время и силы, когда нужно приободрить других, и никакой другой тип личности не делает это настолько стильно и неотразимо.",
            link: "https://www.16personalities.com/ru/lichnost-ESFP",
            image: "/img/ESFP.svg",
        },
    };

    const mbti = MBTI_TYPES[type];

    if (!mbti) {
        return <div>Unknown MBTI type</div>;
    }

    return (
        <div className="mt-2">
            <h4>{mbti.title}</h4>
            <h4 className="text-muted">{mbti.name}</h4>
            <p>{mbti.description}</p>
            <a
                className="text-16 link-underline link-underline-opacity-0 link-primary link-underline-opacity-100-hover"
                href={mbti.link}
                target="_blank"
                rel="noopener noreferrer"
            >
                <OpenInNewWindowSVG /> Читать подробнее
            </a>
            <img src={mbti.image} alt={mbti.title} className="w-100 pt-2" />
        </div>
    );
};

export default MBTI;
