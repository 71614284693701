import React, { useEffect, useState } from "react";
import { WithContext as ReactTags, SEPARATORS } from "react-tag-input";

// Список хобби
const hobbies = [
    "Чтение",
    "Путешествия",
    "Фотография",
    "Рисование",
    "Музыка",
    "Игра на музыкальных инструментах",
    "Танцы",
    "Письмо и ведение блогов",
    "Кулинария",
    "Выпечка",
    "Садоводство",
    "Шитье и вязание",
    "Рукоделие и DIY",
    "Спорт и фитнес",
    "Йога и медитация",
    "Боевые искусства",
    "Компьютерные игры",
    "Настольные игры",
    "Коллекционирование",
    "Рыбалка",
    "Охота",
    "Походы и кемпинг",
    "Волонтерство",
    "Животные и уход за питомцами",
    "Автомобили и мотоциклы",
    "Видеомонтаж и создание контента",
    "Программирование",
    "Настольный теннис",
    "Бег и марафоны",
    "Астрономия",
    "Изучение иностранных языков",
    "Психология",
    "История",
    "Оригами",
    "Флористика",
    "Аквариумистика",
    "Стрельба из лука",
    "Косплей",
    "Работа с деревом",
    "Инвестирование и финансы",
    "Электроника и робототехника",
    "Исследование вин и дегустация",
    "Поиск артефактов и сокровищ",
    "Скалолазание",
    "Серфинг",
    "Фристайл",
    "Картинг",
    "Парусный спорт",
    "Парашютный спорт",
    "Фигурное катание",
    "Пчеловодство",
    "Палеонтология",
    "Антиквариат",
];

const suggestions = hobbies.map((hobbie) => ({
    id: hobbie,
    text: hobbie,
    className: "btn btn-gray-3-bg rounded-1 border-0 sk-hover-gray-3 me-2 px-3",
}));

const TagsComponent = ({ tagsFromProps = [], inputName }) => {
    const [tags, setTags] = useState([]);
    const [tagsString, setTagsString] = useState(""); // Строка для скрытого поля

    useEffect(() => {
        const tagsArr = tagsFromProps.map((tag) => ({
            id: tag,
            text: tag,
            className:
                "btn btn-gray-3-bg rounded-1 border-0 sk-hover-gray-3 me-2 px-3",
        }));
        setTags(tagsArr);
        setTagsString(tagsArr.map((tag) => tag.text).join(",")); // Начальная строка для скрытого поля
    }, [tagsFromProps]);

    const handleDelete = (index) => {
        const newTags = tags.filter((_, i) => i !== index);
        setTags(newTags);
        setTagsString(newTags.map((tag) => tag.text).join(",")); // Обновление скрытого поля
    };

    const handleAddition = (tag) => {
        setTags((prevTags) => {
            const newTags = [...prevTags, tag];
            setTagsString(newTags.map((tag) => tag.text).join(",")); // Обновление скрытого поля
            return newTags;
        });
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTags(newTags);
        setTagsString(newTags.map((tag) => tag.text).join(",")); // Обновление скрытого поля
    };

    const handleTagClick = (index) => {
        console.log("The tag at index " + index + " was clicked");
    };

    const onClearAll = () => {
        setTags([]);
        setTagsString(""); // Очистка скрытого поля
    };

    return (
        <div>
            {/* Скрытое поле для отправки тегов */}
            <input type="hidden" name={inputName} value={tagsString} />

            <ReactTags
                tags={tags}
                suggestions={suggestions}
                separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                handleTagClick={handleTagClick}
                onClearAll={onClearAll}
                inline
                editable
                maxTags={7}
                placeholder="Введите теги"
                inputFieldPosition="top"
                classNames={{
                    suggestions: "react-tags__suggestions", // Для подсказок
                }}
                inputProps={{
                    autoComplete: "off",
                }}
            />
        </div>
    );
};

export default TagsComponent;
