import MedalCard from "./MedalCard";
import ItemListInModal from "./ItemListInModal";
import { useSelector } from "react-redux";
import BalanceIconSVG from "../Icons/BalanceIconSVG";

const MedalsListInModal = ({
    onMedalClick,
    onBtnClick,
    isSecondStepRequired,
    userId,
    action,
}) => {
    const { currentUser } = useSelector((state) => state.users);
    const leader_balance = currentUser?.leader_balance;
    return (
        <>
            <div className="d-flex gap-1 align-items-center">
                <span>Ваш баланс на выдачу медалей: {leader_balance}</span>
                <BalanceIconSVG size={24} />
            </div>
            <ItemListInModal
                itemsSelector={(state) => state?.extraTypes?.extraTypes?.Medal}
                ItemCard={({ item, onItemClick, selectedItemId }) => (
                    <MedalCard
                        medal={item}
                        onMedalClick={onItemClick}
                        selectedMedalId={selectedItemId}
                        disabled={item.meow > leader_balance}
                    />
                )}
                onItemClick={onMedalClick}
                isSecondStepRequired={isSecondStepRequired}
                onActionClick={onBtnClick}
                userId={userId}
                action={action}
            />
        </>
    );
};
export default MedalsListInModal;
