import axios from "axios";

export function formatCommentDate(dateString) {
    const commentDate = new Date(dateString);
    const now = new Date();

    const isToday =
        commentDate.getDate() === now.getDate() &&
        commentDate.getMonth() === now.getMonth() &&
        commentDate.getFullYear() === now.getFullYear();

    if (isToday) {
        const hours = commentDate.getHours();
        const minutes = commentDate.getMinutes();
        const period = hours < 12 ? "утра" : "дня";
        const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
        return `Сегодня в ${formattedTime} ${period}`;
    } else {
        // Если комментарий не сегодня, просто используйте метод toLocaleDateString
        return commentDate.toLocaleDateString();
    }
}

export function generateColorFromId(id) {
    const baseColor = "#BF1B2C";
    const idString = id.toString();
    const idLength = idString.length;

    if (idLength <= 4) {
        let newColor = baseColor;
        for (let i = 0; i < idLength; i++) {
            newColor =
                newColor.substr(0, 1 + i) +
                idString[i] +
                newColor.substr(2 + i);
        }
        return newColor;
    } else {
        console.error("ID length exceeds 4 characters.");
        return baseColor;
    }
}

export function cleanImageUrl(url) {
    return url.replace("storage//storage", "storage");
}

export const handleFileDownload = (file) => {
    // Создаем ссылку на скачивание документа
    const downloadLink = document.createElement("a");
    downloadLink.href =
        file instanceof File
            ? URL.createObjectURL(file)
            : file.original_file_path; // Путь к вашему файлу Word
    downloadLink.download = file.original_file_name || file.name; // Имя файла при скачивании

    // Добавляем ссылку на страницу и эмулируем клик для скачивания
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Удаляем ссылку из DOM
    document.body.removeChild(downloadLink);
};

export function getFileType(file) {
    const newFile = structuredClone(file);
    if (!newFile.name && newFile.file_name) {
        newFile.name = newFile.file_name;
    } else if (!newFile.name && newFile.original_file_name) {
        newFile.name = newFile.original_file_name;
    }
    const fileExtension = newFile.name?.split(".").pop().toLowerCase();
    const fileTypeMappings = {
        pdf: "pdf",
        xls: "xls",
        xlsx: "xls",
        csv: "xls",
        ppt: "ppt",
        pptx: "ppt",
        doc: "doc",
        docx: "doc",
        png: "media",
        jpg: "media",
        jpeg: "media",
        svg: "media",
        webp: "media",
        mp4: "video",
        webm: "video",
        mpeg: "audio",
        mp3: "audio",
        // Добавьте другие расширения или MIME-типы, если необходимо
    };

    const fileType = fileTypeMappings[fileExtension] || "other";

    return fileType;
}

export const createFormData = ({
    content,
    userId,
    action,
    redicsForUsers,
    awardsForUsers = [],
    giftsForUsers = [],
    selectedFiles = [],
    filesToRemove = [],
    department_id = null,
    mentionedUsers = [],
    isPinned = false,
    commentForAdmin = "",
    isMedalTextHidden = false,
}) => {
    const formData = new FormData();
    const token = document.querySelector('meta[name="csrf-token"]').content;

    formData.append("user_id_wall_post", userId);
    formData.append("body", content);
    if (commentForAdmin) {
        formData.append("commentForAdmin", commentForAdmin);
    }
    formData.append("_token", token);
    if (department_id) {
        formData.append("department_id", department_id);
    }
    if (filesToRemove.length) {
        formData.append("files_to_remove", JSON.stringify(filesToRemove));
    }
    if (mentionedUsers.length) {
        formData.append(
            "user_mentions",
            mentionedUsers.map((user) => user.id).join(",")
        );
    }
    formData.append("is_banner", isPinned);

    if (action === "") {
        formData.append("type", "");
    } else if (action === "thanks") {
        formData.append("type", "gratitude");
        formData.append("redik", JSON.stringify(redicsForUsers));
    } else if (action === "reward") {
        formData.append("type", "medal_awarded");
        if (isMedalTextHidden) {
            formData.append("is_text_hidden", 1);
        }
    } else if (action === "badge") {
        formData.append("type", "badges");
    } else if (action === "gift") {
        formData.append("type", "shop");
        console.log("giftsForUsers", giftsForUsers);
        const shop = [];
        giftsForUsers.forEach((gift) => {
            shop.push({
                userId: gift.userId,
                itemId: gift.id,
                quantity: gift.quantity,
            });
        });
        formData.append("shop", JSON.stringify(shop));
    }

    const formatedItemsForUsers = {};
    if (awardsForUsers.length) {
        awardsForUsers.forEach((medal) => {
            formatedItemsForUsers[medal.user?.id] = medal.id;
        });
    } else if (giftsForUsers.length) {
        giftsForUsers.forEach((gift) => {
            formatedItemsForUsers[gift.user?.id] = gift.id;
        });
        // console.log("giftsForUsers", giftsForUsers);
        // console.log("formatedItemsForUsers", formatedItemsForUsers);
    }
    if (Object.keys(formatedItemsForUsers).length) {
        formData.append("redik", JSON.stringify(formatedItemsForUsers));
    }

    selectedFiles.forEach((file) => formData.append("files[]", file));

    return formData;
};

export const sendFormData = async (url, formData) => {
    try {
        const response = await axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data; // Возвращаем данные ответа
    } catch (error) {
        console.error("Ошибка при отправке данных:", error);
        alert("Произошла ошибка при отправке данных.");
        throw error; // Пробрасываем ошибку для обработки в `sendPost`
    }
};
