import LikeSVG from "../Icons/LikeSVG";
import MeowSVG from "../Icons/MeowSVG";
import ShopBasketSVG from "../Icons/ShopBasketSVG";

const ShopCard = ({ item, active, favorite, onBtnClick = () => {} }) => {
    const { name, image, body, summ, id } = item;
    const handleFavoriteItemClick = () => {
        const favArray = JSON.parse(localStorage.getItem("favorites")) || [];
        if (favArray.includes(item.id)) {
            const index = favArray.indexOf(item.id);
            favArray.splice(index, 1);
        } else {
            favArray.push(item.id);
        }
        localStorage.setItem("favorites", JSON.stringify(favArray));
    };

    return (
        <div className="col">
            <div className="card rounded h-100 border-0 position-relative d-flex flex-column align-items-center">
                <div
                    className="card-img-wrapper rounded d-flex align-items-center justify-content-center bg-light"
                    style={{
                        width: "100%",
                        height: "200px",
                        overflow: "hidden",
                    }}
                >
                    <img
                        src={image}
                        className="img-fluid"
                        alt={name}
                        style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </div>
                <div className="card-body w-100 p-2 text-md-center">
                    <h6
                        className={
                            active ? "text-primary mb-1" : "text-muted mb-1"
                        }
                    >
                        {summ} <MeowSVG />
                    </h6>
                    <span className={active ? "text-secondary" : "text-muted"}>
                        {name}
                    </span>
                </div>
                <div className="card-footer bg-transparent border-0 p-2 w-100">
                    <button
                        className={
                            "btn btn-sm w-100 px-4 " +
                            (active ? "btn-primary" : "bg-gray-4 border-gray-4")
                        }
                        disabled={!active}
                        onClick={() => onBtnClick(item)}
                    >
                        <ShopBasketSVG />
                        <span
                            className={"ms-2 " + (active ? "" : "text-white")}
                        >
                            Выбрать
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShopCard;
