import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MeowSVG from "../../Icons/MeowSVG";
import {
    setExtraCommentsForAdmin,
    setExtraCommentsForUser,
} from "../../../redux/extraTypesSlice";

const SecondStage = ({
    item = {},
    selectedUser = {},
    setSelectedUser = () => {},
    inputRef,
    activeInputState,
    setActiveInputState = () => {},
    handleConfirm = () => {},
    onReturnBtnClick = () => {},
    isCommentForUserRequired,
    userCanBeChanged = false,
    isMobile = false,
}) => {
    // console.log("selectedUser", selectedUser);

    const commentForUser = useSelector(
        (state) => state.extraTypes.extraCommentsForUser
    );
    const commentForAdmin = useSelector(
        (state) => state.extraTypes.extraCommentsForAdmin
    );
    const [quantity, setQuantity] = useState(1);
    const { currentUser, working_users: users } = useSelector(
        (state) => state.users
    );
    const dispatch = useDispatch();

    function handleQuantityChange(direction) {
        if (
            direction === "up" &&
            (quantity + 1) * item.summ < currentUser?.meow * 1
        ) {
            setQuantity((prevState) => prevState + 1);
        } else if (direction === "down" && quantity > 1) {
            setQuantity((prevState) => prevState - 1);
        }
    }

    return (
        <div>
            <div className="d-md-block">
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex justify-content-center align-items-center">
                        <img
                            src={item.image}
                            alt={item.name}
                            style={{
                                height: "402px",
                                objectFit: "cover",
                                width: "100%",
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <h6>{item.name}</h6>
                        <div
                            dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <div className="d-flex gap-2 align-items-center">
                            Получатель:{" "}
                            {!userCanBeChanged ? (
                                <span className="text-primary">
                                    {selectedUser?.name}
                                </span>
                            ) : (
                                <div className="d-flex">
                                    <select
                                        className="form-select"
                                        defaultValue={selectedUser?.id}
                                        onChange={(e) => {
                                            const selected = users.find(
                                                (u) =>
                                                    u.id * 1 ===
                                                    e.target.value * 1
                                            );
                                            if (selected) {
                                                setSelectedUser(selected);
                                            }
                                        }}
                                    >
                                        {users.map((u) => (
                                            <option value={u.id} key={u.id}>
                                                {u.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                        {isCommentForUserRequired && (
                            <div>
                                <textarea
                                    placeholder="Введите комментарий для получателя..."
                                    value={commentForUser}
                                    onChange={(e) =>
                                        dispatch(
                                            setExtraCommentsForUser(
                                                e.target.value
                                            )
                                        )
                                    }
                                    className={`form-control ${
                                        commentForUser.trim() === ""
                                            ? "border border-danger-subtle"
                                            : ""
                                    }`}
                                    required
                                ></textarea>
                            </div>
                        )}

                        <div>
                            <textarea
                                placeholder="При необходимости введите комментарий для администратора..."
                                value={commentForAdmin}
                                onChange={(e) =>
                                    dispatch(
                                        setExtraCommentsForAdmin(e.target.value)
                                    )
                                }
                                className="form-control"
                                ref={activeInputState ? inputRef : null}
                                onClick={() => setActiveInputState(true)}
                            ></textarea>
                        </div>
                        <div
                            className={
                                "d-flex justify-content-between align-items-center " +
                                (isMobile && "flex-column gap-4")
                            }
                        >
                            <div className="d-flex gap-2 align-items-center">
                                <div className="d-flex gap-1 align-items-center text-muted">
                                    <div>
                                        <span>Количество</span>
                                    </div>

                                    <div className="d-flex align-items-center gap-1">
                                        <button
                                            className="btn btn-sm border-0 p-0 m-0 text-muted"
                                            onClick={() =>
                                                handleQuantityChange("down")
                                            }
                                        >
                                            -
                                        </button>
                                        <span>{quantity}</span>
                                        <button
                                            className="btn btn-sm border-0 p-0 m-0 text-muted"
                                            onClick={() =>
                                                handleQuantityChange("up")
                                            }
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div className="d-flex gap-1 align-items-center">
                                    <span>Спишется</span>
                                    <span>{item.summ * quantity}</span>
                                    <MeowSVG />
                                </div>
                            </div>
                            <div className="d-flex">
                                <button
                                    className="btn btn-sm border-0"
                                    onClick={onReturnBtnClick}
                                >
                                    Назад
                                </button>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                        handleConfirm({
                                            ...item,
                                            quantity,
                                            totalSumm: item.summ * quantity,
                                            commentForUser,
                                            commentForAdmin,
                                        })
                                    }
                                    disabled={
                                        isCommentForUserRequired &&
                                        !commentForUser
                                    }
                                >
                                    Подтвердить покупку
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecondStage;
