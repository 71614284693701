import React, { useEffect } from "react";
import BurgerMenuContent from "./BurgerMenu/BurgerMenuContent";

function BurgerMenu({
    MenuContent = BurgerMenuContent,
    showToggleButton = true,
    isOpen = false,
    id = "staticBackdrop",
    onClose = () => {},
}) {
    console.log("BurgerMenu isOpen:", isOpen);

    useEffect(() => {
        const menu = document.getElementById(id);
        if (isOpen && menu) {
            menu.classList.add("show"); // Показываем меню
            menu.style.visibility = "visible";
            menu.style.transform = "none"; // Отменяем скрытие
            document.body.classList.add("offcanvas-backdrop"); // Добавляем затемнение фона
        } else if (!isOpen && menu) {
            menu.classList.remove("show"); // Скрываем меню
            menu.style.visibility = "";
            menu.style.transform = "";
            document.body.classList.remove("offcanvas-backdrop"); // Убираем затемнение фона
        }
    }, [isOpen]);

    const closeMenu = () => {
        const menu = document.getElementById(id);
        if (menu) {
            menu.classList.remove("show"); // Скрываем меню
            menu.style.visibility = "";
            menu.style.transform = "";
            document.body.classList.remove("offcanvas-backdrop"); // Убираем затемнение фона
        }
        onClose();
    };

    return (
        <>
            {showToggleButton && (
                <button
                    className="btn d-block d-lg-none rounded-start bg-white border-0"
                    type="button"
                    onClick={() => {
                        const menu = document.getElementById(id);
                        if (menu) {
                            menu.classList.add("show");
                            menu.style.visibility = "visible";
                            menu.style.transform = "none";
                            document.body.classList.add("offcanvas-backdrop");
                        }
                    }}
                >
                    <img src="/img/sidebar-menu.svg" alt="Меню" />
                </button>
            )}

            <div
                className="offcanvas offcanvas-start overflow-auto"
                tabIndex="-1"
                id={id}
                aria-labelledby="staticBackdropLabel"
                style={{ width: "300px" }}
            >
                <button
                    type="button"
                    className="btn-close position-absolute top-0 end-0 mt-3 me-3"
                    onClick={closeMenu}
                    aria-label="Close"
                ></button>

                <MenuContent />
            </div>
        </>
    );
}

export default BurgerMenu;
