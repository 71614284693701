import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import StenaComponent from "./StenaComponent";
import { useSelector } from "react-redux";
import ReusableModal from "../ReusableModal";
import { useTransition, animated } from "react-spring";
import ClipLoader from "react-spinners/ClipLoader";

const InfiniteScrollComponent = ({
    hasMore,
    fetchMoreData,
    filter,
    Loadings,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(null);
    const { stenas, loading } = useSelector((state) => state.stenas);
    const [filteredStenas, setFilteredStenas] = useState(stenas);

    // Анимация с использованием react-spring для спиннера
    const transitions = useTransition(hasMore || loading, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 300 },
    });

    useEffect(() => {
        const filteredStenas = filter
            ? stenas.filter((stena) => stena[filter.field] === filter.value)
            : stenas;
        setFilteredStenas(filteredStenas);
    }, [stenas, loading]);

    return (
        <div>
            {(loading || filteredStenas.length === 0) && (
                <div className="d-flex justify-content-center my-4">
                    <ClipLoader size={50} color={"#FF0000"} loading={true} />
                </div>
            )}

            <div className="d-none d-md-block">
                <InfiniteScroll
                    dataLength={filteredStenas.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={transitions((styles, item) =>
                        item ? (
                            <animated.div style={styles}>
                                <ClipLoader
                                    size={35}
                                    color={"#FF0000"}
                                    loading={true}
                                />
                            </animated.div>
                        ) : null
                    )}
                    endMessage={
                        hasMore ? null : (
                            <p style={{ textAlign: "center" }}>
                                <b>Все записи загружены.</b>
                            </p>
                        )
                    }
                    className="d-flex flex-column gap-4"
                >
                    {filteredStenas.map((stena) => (
                        <React.Fragment key={stena.id}>
                            <StenaComponent
                                key={"stena_" + stena.id}
                                stena={stena}
                                model={"Stena"}
                                onOpenModal={() => setIsModalOpen(stena.id)}
                            />
                            {isModalOpen === stena.id && (
                                <ReusableModal
                                    key={stena.id}
                                    content={
                                        <StenaComponent
                                            key={"modal_" + stena.id}
                                            stena={stena}
                                            model={"Stena"}
                                            textTruncate={false}
                                        />
                                    }
                                    show={isModalOpen}
                                    onClose={() => setIsModalOpen(null)}
                                    large={true}
                                    footer={false}
                                    header={false}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </InfiniteScroll>
            </div>

            <div className="d-block d-md-none">
                <InfiniteScroll
                    dataLength={filteredStenas.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={transitions((styles, item) =>
                        item ? (
                            <animated.div style={styles}>
                                <ClipLoader
                                    size={35}
                                    color={"#FF0000"}
                                    loading={true}
                                />
                            </animated.div>
                        ) : null
                    )}
                    endMessage={
                        hasMore ? null : (
                            <p style={{ textAlign: "center" }}>
                                <b>Все записи загружены.</b>
                            </p>
                        )
                    }
                    className="d-flex flex-column gap-2"
                >
                    {filteredStenas.map((stena) => (
                        <React.Fragment key={stena.id}>
                            <StenaComponent
                                key={"stena_" + stena.id}
                                stena={stena}
                                model={"Stena"}
                                onOpenModal={() => setIsModalOpen(stena.id)}
                            />
                            {isModalOpen === stena.id && (
                                <ReusableModal
                                    key={stena.id}
                                    content={
                                        <StenaComponent
                                            key={"modal_" + stena.id}
                                            stena={stena}
                                            model={"Stena"}
                                            textTruncate={false}
                                        />
                                    }
                                    show={isModalOpen}
                                    onClose={() => setIsModalOpen(null)}
                                    large={true}
                                    footer={false}
                                    header={false}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default InfiniteScrollComponent;
