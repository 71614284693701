import React, { useEffect, useRef, useState } from "react";
import LikeSVG from "../Icons/LikeSVG";
import axios from "axios";
import { useSelector } from "react-redux";
import LikePopover from "./LikePopover";

function LikeButton({ model, data }) {
    const [isLikedByUser, setIsLikedByUser] = useState(false);
    const [likesCount, setLikesCount] = useState(data?.likes?.length);
    const [showPopover, setShowPopover] = useState(false);
    const currentUser = useSelector((state) => state.users?.currentUser);
    const ref = useRef(null);

    useEffect(() => {
        if (currentUser && data.likes) {
            setIsLikedByUser(
                data.likes.find((like) => like.user_id === currentUser.id)
            );
        } else if (data.isLikedByUser) {
            setIsLikedByUser(data.isLikedByUser);
        } else {
            setIsLikedByUser(false);
        }
        setLikesCount(data?.likes?.length);
    }, [currentUser, data]);

    const handleLikeClick = () => {
        // console.log("data in handleLikeClick", data);
        setIsLikedByUser(!isLikedByUser);

        // setLikesCount((prevState) => prevState + 1);1
        const res = axios
            .get(`/like/${model}/${data.id}`)
            .then((response) => {
                console.log("Liked");
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const usersWhoLiked = data.likes?.map((like) => like.user_id) || [];

    function handleLikePopoverChange(value) {
        if (usersWhoLiked.length) {
            if (value && !showPopover) {
                setShowPopover(value);
            } else if (!value && showPopover) {
                setShowPopover(value);
            }
        }
    }

    return (
        <div
            onMouseEnter={() => handleLikePopoverChange(true)}
            onMouseLeave={() => handleLikePopoverChange(false)}
        >
            <div
                onClick={handleLikeClick}
                role="button"
                className={`like-button d-flex align-items-center gap-1 sk-bg-gray-2 rounded px-2 py-1 ${
                    isLikedByUser ? "text-primary" : "text-muted"
                }`}
                data-id={data.id}
                data-likes={JSON.stringify(data.usersWhoLiked)}
                ref={ref}
            >
                <div>
                    <LikeSVG isLiked={isLikedByUser} />
                </div>
                <div>
                    <span className="text-14">{likesCount || 0}</span>
                </div>
            </div>
            {showPopover ? (
                <LikePopover
                    usersWhoLiked={usersWhoLiked}
                    target={ref.current}
                    handleLikePopoverChange={handleLikePopoverChange}
                />
            ) : (
                ""
            )}
        </div>
    );
}

export default LikeButton;
