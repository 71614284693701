import React, { useEffect, useState } from "react";
import DropdownItem from "./DropdownItem";
import AccordionItem from "./AccordionItem";
import ActionButton from "./ActionButton";
import { links } from "./links";
import UserLogo from "../Stena/UserLogo";
import { useSelector } from "react-redux";
import Pusher from "pusher-js";

const renderLinks = (category) =>
    links[category].map((item) => <DropdownItem key={item.id} item={item} />);

function BurgerMenuContent() {
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
    const { currentUser } = useSelector((state) => state.users);
    const [authUserId, setAuthUserId] = useState(null);
    const [medalBalance, setMedalBalance] = useState(0);

    useEffect(() => {
        const userDataElement = document.getElementById("user-data");
        if (userDataElement) {
            const balance =
                Number(userDataElement.getAttribute("data-medal-balance")) || 0;
            setMedalBalance(balance);
        }
    }, []);

    useEffect(() => {
        const userId = document.getElementById("Authdatausers")
            ? Number(
                  document
                      .getElementById("Authdatausers")
                      .getAttribute("data-user")
              )
            : null;
        setAuthUserId(userId);

        console.log("authUserId при загрузке страницы:", userId);

        fetch("/notifications/count")
            .then((response) => response.json())
            .then((data) => {
                console.log("Ответ от /notifications/count:", data);
                setHasUnreadNotifications(data.count > 0);
            })
            .catch((error) =>
                console.error("Ошибка загрузки уведомлений:", error)
            );
    }, []);

    useEffect(() => {
        if (!authUserId) {
            console.log("authUserId отсутствует, WebSocket не подключаем");
            return;
        }

        console.log("Подключаем WebSocket для authUserId:", authUserId);

        Pusher.logToConsole = false;
        const pusher = new Pusher("AnyKeyYouLike", {
            cluster: "eu",
            wsHost: window.location.hostname,
            wsPort: 6001,
            forceTLS: false,
            disableStats: true,
            enabledTransports: ["ws", "wss"],
        });

        const userChannel = pusher.subscribe("home");
        // Приходит новое уведомление
        userChannel.bind("App\\Events\\NotificationEvent", (data) => {
            console.log("Получен NotificationEvent:", data);

            if (data.user.id === authUserId) {
                console.log(
                    "Уведомление принадлежит текущему пользователю, показываем точку"
                );
                setHasUnreadNotifications(true);
            }
        });

        userChannel.bind("App\\Events\\NotificationDeleteEvent", (data) => {
            console.log("Получен NotificationDeleteEvent:", data);

            if (data.user_id === authUserId) {
                console.log("Удалены уведомления у текущего пользователя");

                if (!data.has_notifications) {
                    console.log(
                        "Скрываем точку, так как больше нет уведомлений"
                    );
                    setHasUnreadNotifications(false);
                }
            }
        });

        return () => {
            console.log("Отключаем WebSocket для authUserId:", authUserId);
            userChannel.unbind_all();
            userChannel.unsubscribe();
        };
    }, [authUserId]);

    useEffect(() => {
        console.log(
            "Состояние hasUnreadNotifications изменилось:",
            hasUnreadNotifications
        );
    }, [hasUnreadNotifications]);

    const isRukovoditel = currentUser?.user_details?.rukovoditel === "true";
    const { meow: meowcoins, redik: rediks } = currentUser || {};
    console.log("currentUser data:", currentUser);

    return (
        <div className="d-flex flex-column">
            <div className="container-fluid gap-2">
                <div className="ps-2 pt-2 pe-2">
                    <AccordionItem
                        id="profileCollapse"
                        title="Профиль"
                        icons={
                            currentUser?.id && (
                                <UserLogo
                                    user={currentUser}
                                    size="extra-small"
                                />
                            )
                        }
                    >
                        {renderLinks("profile")}
                    </AccordionItem>
                    <div className="ps-2 pt-2 pe-2">
                        <div className="d-flex gap-2 align-items-center">
                            <div className="btn border-0 d-flex gap-2 px-0">
                                <img
                                    className="w-20"
                                    src="/img/bell-28px.svg"
                                    alt="Уведомления"
                                />
                                <a
                                    href="/bell"
                                    className="link-underline d-md-none link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                                >
                                    Уведомления
                                </a>
                            </div>
                            {hasUnreadNotifications && (
                                <div
                                    className="rounded-circle bg-primary"
                                    style={{ width: "8px", height: "8px" }}
                                ></div>
                            )}
                        </div>

                        <div className="btn border-0 d-flex gap-2 ps-0">
                            <img
                                className="w-20"
                                src="/img/group-light.svg"
                                alt="Коллеги"
                            />
                            <a
                                href="/users"
                                className="link-underline d-md-none link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                            >
                                Коллеги
                            </a>
                        </div>
                        <div className="btn border-0 d-flex gap-2 ps-0">
                            <img
                                className="w-20"
                                src="/img/meowmarket-light.svg"
                                alt="МяуМаркет"
                            />
                            <a
                                href="/shop"
                                className="link-underline d-md-none link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                            >
                                МяуМаркет
                            </a>
                        </div>
                    </div>

                    <AccordionItem
                        id="megaphoneCollapse"
                        title="Будь в курсе"
                        icon="/img/megaphone.svg"
                    >
                        {renderLinks("notifications")}
                    </AccordionItem>

                    <AccordionItem
                        id="graduationCollapse"
                        title="Обучение и развитие"
                        icon="/img/graduation-cap.svg"
                    >
                        {renderLinks("education")}
                    </AccordionItem>

                    <AccordionItem
                        id="profileCircleCollapse"
                        title="Сервисы сотрудника"
                        icon="/img/profile-circle.svg"
                    >
                        {renderLinks("employeeServices")}
                    </AccordionItem>
                </div>
            </div>

            <div className="container-fluid gap-2">
                <div className="row align-items-center">
                    <div className="d-flex flex-column gap-2 ms-2">
                        <div className="d-flex gap-2 align-items-center">
                            <div className="d-flex align-items-center col-3 gap-1">
                                <img
                                    style={{ width: "18px", height: "18px" }}
                                    src="/img/meow.svg"
                                    alt="Мяу-коины"
                                />
                                {meowcoins}
                            </div>
                            <ActionButton
                                icon="/img/simple-cart.svg"
                                label="Потратить"
                                link="/shop"
                            />
                        </div>

                        <div className="d-flex gap-2 align-items-center">
                            <div className="d-flex align-items-center col-3 gap-1">
                                <img
                                    style={{ width: "18px", height: "18px" }}
                                    src="/img/redik.svg"
                                    alt="Редики"
                                />
                                {rediks}
                            </div>
                        </div>
                        {isRukovoditel && (
                            <div className="d-flex gap-2 align-items-center">
                                <div className="d-flex align-items-center col-3 gap-1 mt-2">
                                    <img
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                        }}
                                        src="/img/coins.svg"
                                        alt="Редики"
                                    />
                                    {medalBalance}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BurgerMenuContent;
