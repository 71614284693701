import React, { useEffect, useState } from "react";
import { WithContext as ReactTags, SEPARATORS } from "react-tag-input";
//List of countries in the world
const hobbies = [
    "Чтение",
    "Путешествия",
    "Фотография",
    "Рисование",
    "Музыка",
    "Игра на музыкальных инструментах",
    "Танцы",
    "Письмо и ведение блогов",
    "Кулинария",
    "Выпечка",
    "Садоводство",
    "Шитье и вязание",
    "Рукоделие и DIY",
    "Спорт и фитнес",
    "Йога и медитация",
    "Боевые искусства",
    "Компьютерные игры",
    "Настольные игры",
    "Коллекционирование",
    "Рыбалка",
    "Охота",
    "Походы и кемпинг",
    "Волонтерство",
    "Животные и уход за питомцами",
    "Автомобили и мотоциклы",
    "Видеомонтаж и создание контента",
    "Программирование",
    "Настольный теннис",
    "Бег и марафоны",
    "Астрономия",
    "Изучение иностранных языков",
    "Психология",
    "История",
    "Оригами",
    "Флористика",
    "Аквариумистика",
    "Стрельба из лука",
    "Косплей",
    "Работа с деревом",
    "Инвестирование и финансы",
    "Электроника и робототехника",
    "Исследование вин и дегустация",
    "Поиск артефактов и сокровищ",
    "Скалолазание",
    "Серфинг",
    "Фристайл",
    "Картинг",
    "Парусный спорт",
    "Парашютный спорт",
    "Фигурное катание",
    "Пчеловодство",
    "Палеонтология",
    "Антиквариат",
];

const suggestions = hobbies.map((hobbie) => {
    return {
        id: hobbie,
        text: hobbie,
        className:
            "btn btn-gray-3-bg rounded-1 border-0 sk-hover-gray-3 me-2 px-3",
    };
});

const TagsComponent = ({ tagsFromProps }) => {
    const [tags, setTags] = useState([]);
    useEffect(() => {
        const tagsArr = [];
        tagsFromProps.forEach((tag) => {
            tagsArr.push({
                id: tag,
                text: tag,
                className:
                    "btn btn-gray-3-bg rounded-1 border-0 sk-hover-gray-3 me-2 px-3",
            });
        });
        setTags(tagsArr);
    }, [tagsFromProps]);

    const handleDelete = (index) => {
        setTags(tags.filter((_, i) => i !== index));
    };

    const onTagUpdate = (index, newTag) => {
        const updatedTags = [...tags];
        updatedTags.splice(index, 1, newTag);
        setTags(updatedTags);
    };

    const handleAddition = (tag) => {
        setTags((prevTags) => {
            return [...prevTags, tag];
        });
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = (index) => {
        console.log("The tag at index " + index + " was clicked");
    };

    const onClearAll = () => {
        setTags([]);
    };

    return (
        <div>
            {tags.length > 0 && (
                <ReactTags
                    tags={tags}
                    suggestions={suggestions}
                    separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    handleTagClick={handleTagClick}
                    onTagUpdate={onTagUpdate}
                    inline
                    editable
                    onClearAll={onClearAll}
                    maxTags={7}
                    placeholder="Введите теги"
                />
            )}
        </div>
    );
};

export default TagsComponent;
