import React, { useState, useEffect, useRef } from "react";
import BurgerMenu from "./BurgerMenu";

const isLeaderPosition = (positionName) => {
    // Проверка на наличие ключевых слов для роли руководителя или директора
    const leaderKeywords = ["руководитель", "директор"];
    return leaderKeywords.some((keyword) =>
        positionName?.toLowerCase().includes(keyword)
    );
};

const isZamLeaderPosition = (positionName) => {
    // Проверка на наличие ключевых слов для роли руководителя или директора
    const leaderKeywords = ["заместитель", "бригадир"];
    return leaderKeywords.some((keyword) =>
        positionName?.toLowerCase().includes(keyword)
    );
};

// Конфигурация для моделей и переводов
const SEARCH_CONFIG = {
    models: {
        all: "Везде",
        user: "Сотрудники",
        stena: "Стена",
        shop: "МяуМаркет",
        Training: "Обучение",
    },
    modelTranslationsRU: {
        all: "порталу вместе с Кошкой Каролиной",
        user: "сотрудникам",
        stena: "стене",
        shop: "МяуМаркету",
        Training: "Обучению",
    },
    // Логика отображения аватаров/иконок
    renderAvatar: (result) => {
        if (result.type === "user") {
            return result.avatar_url ? (
                <img
                    src={`/${result.avatar_url}`}
                    alt={result.name}
                    className="rounded-circle"
                    style={{
                        width: "48px",
                        height: "48px",
                        objectFit: "cover",
                    }}
                />
            ) : (
                <div
                    className="flex-shrink-0 position-relative rounded-circle bg-primary text-white d-flex align-items-center justify-content-center mr-2"
                    style={{ width: "48px", height: "48px" }}
                >
                    {result.short_name || "стена"}
                </div>
            );
        } else if (result.type === "shop") {
            return (
                <img
                    src={`${result.image}`}
                    alt={result.name}
                    className="rounded-circle"
                    style={{
                        width: "48px",
                        height: "48px",
                        objectFit: "cover",
                    }}
                />
            );
        } else {
            return (
                <div
                    className="flex-shrink-0 position-relative rounded-circle bg-primary text-white d-flex align-items-center justify-content-center mr-2"
                    style={{ width: "48px", height: "48px" }}
                >
                    {result.short_name || "стена"}
                </div>
            );
        }
    },
};

const SearchComponent = () => {
    const [query, setQuery] = useState("");
    const [model, setModel] = useState("all");
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModelDropdown, setShowModelDropdown] = useState(false);
    const [showResultsDropdown, setShowResultsDropdown] = useState(false);
    const [moreResultsCount, setMoreResultsCount] = useState(0);
    const [hasMoreResults, setHasMoreResults] = useState(false);

    const searchRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                searchRef.current &&
                !searchRef.current.contains(event.target)
            ) {
                setShowModelDropdown(false);
                setShowResultsDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const fetchData = async () => {
        if (query.length >= 2) {
            setIsLoading(true);
            try {
                const response = await fetch(
                    `/search?model=${model}&query=${encodeURIComponent(query)}`
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                let combinedResults = [];
                if (model === "all") {
                    for (let key in data) {
                        if (data[key]?.data) {
                            combinedResults = combinedResults.concat(
                                data[key].data
                            );
                        }
                    }
                } else {
                    combinedResults = data[model]?.data || [];
                }

                setResults(combinedResults);
                setMoreResultsCount(
                    data[model]?.count - combinedResults.length || 0
                );
                setHasMoreResults(data[model]?.more || false);
                setShowResultsDropdown(true);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setResults([]);
            setShowResultsDropdown(false);
        }
    };

    useEffect(() => {
        const timerId = setTimeout(fetchData, 500);
        return () => clearTimeout(timerId);
    }, [query, model]);

    return (
        <div ref={searchRef} className="position-relative">
            <div className="input-group sk-active-primary">
                <button
                    className="btn btn-gray-3-bg d-none d-lg-flex border-gray-4-bg dropdown-toggle text-gray-6-text align-items-center"
                    type="button"
                    aria-expanded={showModelDropdown}
                    onClick={() => setShowModelDropdown(!showModelDropdown)}
                >
                    {SEARCH_CONFIG.models[model]}
                </button>
                <ul
                    className={`dropdown-menu${
                        showModelDropdown ? " show" : ""
                    }`}
                    style={{ cursor: "pointer", zIndex: 9099999999 }}
                >
                    {Object.keys(SEARCH_CONFIG.models).map((key) => (
                        <li key={key}>
                            <a
                                className="dropdown-item"
                                onClick={() => {
                                    setModel(key);
                                    setShowModelDropdown(false);
                                }}
                            >
                                {SEARCH_CONFIG.models[key]}
                            </a>
                        </li>
                    ))}
                </ul>

                <BurgerMenu />
                <input
                    type="text"
                    className={`form-control form-control-lg ${
                        window.innerWidth < 768
                            ? "rounded-end border-0"
                            : "border-gray-4"
                    }`}
                    aria-label="Поиск по сайту"
                    placeholder={`Поиск по ${SEARCH_CONFIG.modelTranslationsRU[model]}`}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <span className="input-group-text d-none d-lg-flex align-items-center border-gray-4">
                    {isLoading ? (
                        <div className="loader"></div>
                    ) : (
                        <SearchIcon />
                    )}
                </span>
            </div>

            {showResultsDropdown && (
                <div className="sk-search-inactive col-5 ps-0">
                    <div
                        className="dropdown-menu show w-100"
                        style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            maxHeight: "300px",
                            overflowY: "auto",
                        }}
                    >
                        {results.length > 0 ? (
                            results.map((result) => {
                                if (result?.id && result?.role_id !== 3) {
                                    const url = `/${result.type}/${result.id}`;
                                    return (
                                        <a
                                            key={result.id}
                                            className="dropdown-item text-overflow d-flex align-items-center"
                                            href={url}
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {SEARCH_CONFIG.renderAvatar(result)}
                                            <div>
                                                <div className="m-2 mb-1 text-wrap d-flex align-items-center">
                                                    {isLeaderPosition(
                                                        result.position_name
                                                    ) && (
                                                        <b
                                                            className="d-inline-flex align-items-center justify-content-center bg-primary text-white rounded-circle me-2"
                                                            style={{
                                                                width: "16px",
                                                                height: "16px",
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            ★
                                                        </b>
                                                    )}
                                                    {!isLeaderPosition(
                                                        result.position_name
                                                    ) &&
                                                        isZamLeaderPosition(
                                                            result.position_name
                                                        ) && (
                                                            <b
                                                                className="d-inline-flex align-items-center justify-content-center  text-white rounded-circle me-2"
                                                                style={{
                                                                    width: "16px",
                                                                    height: "16px",
                                                                    fontSize:
                                                                        "12px",
                                                                    backgroundColor:
                                                                        "#0d6efd", // Синий цвет
                                                                }}
                                                            >
                                                                ★
                                                            </b>
                                                        )}
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.name,
                                                        }}
                                                    />
                                                </div>
                                                {result.department_name && (
                                                    <div className="ms-2 text-gray-6-text">
                                                        {result.department_name}{" "}
                                                        - {result.position_name}
                                                    </div>
                                                )}
                                                {result.summ && (
                                                    <div className="p-1 text-primary">
                                                        <img
                                                            src="/img/meow.svg"
                                                            alt="Meow Coin"
                                                            style={{
                                                                width: "18px",
                                                                height: "18px",
                                                            }}
                                                        />
                                                        &nbsp;{result.summ}
                                                    </div>
                                                )}
                                            </div>
                                        </a>
                                    );
                                }
                                return null;
                            })
                        ) : (
                            <div className="dropdown-item">Нет результатов</div>
                        )}

                        {hasMoreResults && (
                            <div className="card card-body">
                                <a
                                    className="bange"
                                    href={`/isearch?model=${model}&query=${encodeURIComponent(
                                        query
                                    )}&page=2`}
                                >
                                    Показать еще результаты
                                </a>
                                <span>({moreResultsCount} оставшихся)</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

// Иконка поиска
const SearchIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17 17L21 21"
            stroke="#8C8C8C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z"
            stroke="#8C8C8C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SearchComponent;
