import { useState } from "react";

const TextHiddenIconSVG = ({
    active = false,
    color,
    handleClick = () => {},
}) => {
    const [stroke, setStroke] = useState(color || "#BFBFBF");

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="button"
            onMouseEnter={() => setStroke(color || "#8C8C8C")}
            onMouseLeave={() => setStroke(color || "#BFBFBF")}
            onClick={handleClick}
        >
            <g clipPath="url(#clip0_6642_9998)">
                <path
                    d="M9 5L9 17M9 17H7M9 17H11"
                    stroke={!active ? stroke : "#202227"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15 7V5L3 5L3 7"
                    stroke={!active ? stroke : "#202227"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.92 13.3068C22.123 13.5097 22.123 13.8387 21.92 14.0416L19.3481 16.6135L21.92 19.1855C22.123 19.3884 22.123 19.7174 21.92 19.9203C21.7171 20.1232 21.3881 20.1232 21.1852 19.9203L18.6133 17.3484L16.0414 19.9203C15.8384 20.1232 15.5094 20.1232 15.3065 19.9203C15.1036 19.7174 15.1036 19.3884 15.3065 19.1855L17.8784 16.6135L15.3065 14.0416C15.1036 13.8387 15.1036 13.5097 15.3065 13.3068C15.5094 13.1038 15.8384 13.1038 16.0414 13.3068L18.6133 15.8787L21.1852 13.3068C21.3881 13.1038 21.7171 13.1038 21.92 13.3068Z"
                    fill={!active ? stroke : "#202227"}
                />
            </g>
            <defs>
                <clipPath id="clip0_6642_9998">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default TextHiddenIconSVG;
