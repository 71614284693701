import React, { useState } from "react";
import { useSelector } from "react-redux";

const ThanksInModalComponent = ({
    userId,
    onBtnClick = () => {},
    action,
    onClose = () => {},
}) => {
    const [selectedBadge, setSelectedBadge] = useState(0);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { users } = useSelector((state) => state.users);
    // console.log("users", users);

    const handleBadgeSelect = (badge) => {
        setSelectedBadge(badge);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        // alert(`Sent Thank You to ${userId}: ${message}`);
        try {
            await onBtnClick({
                action,
                id: userId,
                commentForUser: ` <a class="text-primary link-underline link-underline-opacity-0" href="/user/${userId}">@${
                    users.find((u) => u.id * 1 === userId * 1)?.name
                } <span class="redics-count text-primary"> </span></a>&nbsp;${message}`,
                userId,
                value: selectedBadge,
            });
            // onClose();
        } catch (error) {
            console.error("Error sending thank you:", error);
            alert("Произошла ошибка при отправке благодарности.");
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    return (
        <>
            {/* <div className="d-flex mb-3 gap-2">
                <button
                    className="btn d-flex gap-2"
                    style={{
                        backgroundColor:
                            selectedBadge === 1 ? "#F4CB01" : "#F6F6F6",
                    }}
                    onClick={() => handleBadgeSelect(1)}
                >
                    <RedikSVG24Icon />
                    <span>1</span>
                </button>
                <button
                    className="btn d-flex gap-2"
                    style={{
                        backgroundColor:
                            selectedBadge === 2 ? "#F4CB01" : "#F6F6F6",
                    }}
                    onClick={() => handleBadgeSelect(2)}
                >
                    <RedikSVG24Icon />
                    <span>2</span>
                </button>
            </div> */}

            <textarea
                className="form-control mb-3 bg-gray-2"
                placeholder="За что вы благодарите?"
                rows="3"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <div className="d-flex gap-2">
                <button className="btn btn-secondary" onClick={onClose}>
                    Отмена
                </button>
                {!isLoading ? (
                    <button className="btn btn-primary" onClick={handleSubmit}>
                        Отправить
                    </button>
                ) : (
                    <button className="btn btn-primary" disabled>
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Отправка...
                    </button>
                )}
            </div>
        </>
    );
};

export default ThanksInModalComponent;
