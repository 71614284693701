import { useDispatch, useSelector } from "react-redux";
import ShopList from "./PostField/ShopList";
import { useEffect, useState } from "react";
import { fetchExtraTypes } from "../redux/extraTypesSlice";
import { fetchCurrentUserId, fetchUsers } from "../redux/usersSlice";
import SearchSVG from "./Icons/SearchSVG";
import SecondStage from "./PostField/ShopModal/SecondStage";
import ThirdStage from "./PostField/ShopModal/ThirdStage";
import axios from "axios";
import MeowSVG from "./Icons/MeowSVG";
import BurgerMenu from "./BurgerMenu";
import ShopMobileMenuContent from "./ShopMobileMenuContent";

const ShopWrapper = ({ activeCategory = 0 }) => {
    const { Shop } = useSelector((state) => state.extraTypes.extraTypes);
    const {
        extraCommentsForAdmin: commentForAdmin,
        extraCommentsForUser: commentForUser,
    } = useSelector((state) => state.extraTypes);
    // console.log("Shop", Shop);

    const { currentUser } = useSelector((state) => state.users);
    // console.log("currentUser", currentUser);

    const dispatch = useDispatch();

    const [inputValue, setInputValue] = useState("");
    const [showAccessibleOnly, setShowAccessibleOnly] = useState(false);
    const [sorting, setSorting] = useState({ title: "", id: null });
    const [filtredItems, setFiltredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [thirdStageActive, setThirdStageActive] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const filterFromRedux = useSelector((state) => state.shop.filter);
    // console.log("filterFromRedux", filterFromRedux);

    function resetStateToDefault() {
        setSelectedItem(null);
        setThirdStageActive(false);
        setSelectedUser(currentUser);
        setInputValue("");
        // setShowAccessibleOnly(false);
        // setSorting({ title: "", id: null });
    }

    useEffect(() => {
        if (currentUser.id) {
            setSelectedUser(currentUser);
        }
    }, [currentUser]);

    useEffect(() => {
        if (filterFromRedux) {
            handleFilterAndSort({ id: filterFromRedux });
            setIsMenuOpen(false);
        }
    }, [filterFromRedux]);

    // console.log("activeCategory", activeCategory);

    async function fetchData() {
        await dispatch(fetchExtraTypes(true));
        await dispatch(fetchUsers(true));
        await dispatch(fetchCurrentUserId(true));
    }

    useEffect(() => {
        if (!Shop) {
            fetchData();
        } else {
            handleFilterAndSort();
        }
    }, [Shop, inputValue, showAccessibleOnly, sorting, activeCategory]);

    const handleFilterAndSort = (selectedCategory = null) => {
        // console.log("selectedCategory", selectedCategory);
        let filtered;
        if (!selectedCategory || selectedCategory.id === 0) {
            filtered = Shop.filter((item) =>
                activeCategory * 1 ? item.cat * 1 === activeCategory * 1 : item
            );
        } else {
            // console.log("selectedCategory", selectedCategory);

            filtered = Shop.filter(
                (item) => item.cat * 1 === selectedCategory.id * 1
            );
        }

        if (inputValue) {
            filtered = filtered.filter((item) =>
                item.name.toLowerCase().includes(inputValue.toLowerCase())
            );
        }

        if (showAccessibleOnly) {
            filtered = filtered.filter((item) => item.summ <= currentUser.meow);
        }

        if (sorting && sorting.id) {
            filtered = filtered.sort((a, b) => {
                if (sorting.id === "asc") {
                    return sorting.type === "name"
                        ? a.name.localeCompare(b.name)
                        : a.summ - b.summ;
                } else {
                    return sorting.type === "name"
                        ? b.name.localeCompare(a.name)
                        : b.summ - a.summ;
                }
            });
        }

        // console.log("filtered", filtered);

        setFiltredItems(filtered);
    };

    function handleItemSelect(item) {
        console.log("handleItemSelect", item);

        setSelectedItem(item);
    }

    async function handleConfirm(item) {
        setSelectedItem(item);
        try {
            await sendPurchase(item);
            setThirdStageActive(true);
        } catch (error) {
            console.log(error);
            alert("Не удалось купить товар");
            throw error;
        }
    }

    async function sendPurchase(item) {
        console.log("sendPurchase", item);
        try {
            console.log("userId", selectedUser.id);
            const res = await axios.post("/shop/purchases", {
                userId: selectedUser.id, //еще и для другого надо
                itemId: item.id,
                quantity: item.quantity,
                commentForAdmin,
                commentForUser,
            });
            console.log("res", res);
            return res;
        } catch (error) {
            // console.log(error);
            // alert("Не удалось купить товар");
            throw error;
        }
    }

    return (
        <div>
            {/* Десктопная версия */}
            <div className="d-none d-md-block">
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex gap-3 align-items-center">
                        {/* Поле поиска */}
                        <div className="input-group align-items-center">
                            <span className="z-999">
                                <button
                                    className="btn border-0 me-n5"
                                    type="button"
                                >
                                    <SearchSVG />
                                </button>
                            </span>
                            <input
                                type="text"
                                className="form-control ps-5 rounded bg-gray-2 border-gray-2"
                                placeholder="Поиск по МяуМаркету"
                                aria-label="Поиск по МяуМаркету"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        </div>

                        {/* Чекбокс "Доступные мне" */}
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="accessibleOnly"
                                checked={showAccessibleOnly}
                                onChange={() =>
                                    setShowAccessibleOnly(!showAccessibleOnly)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="accessibleOnly"
                            >
                                Доступные мне
                            </label>
                        </div>

                        {/* Сортировка */}
                        <div className="dropdown">
                            <button
                                className="btn text-muted text-nowrap p-0 border-0"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Сортировка:{" "}
                                <span className="text-lowercase">
                                    {sorting.title || "Без сортировки"}
                                </span>
                            </button>
                            <div className="dropdown-menu pe-5">
                                {[
                                    {
                                        id: "asc",
                                        title: "По названию (возрастание)",
                                        type: "name",
                                    },
                                    {
                                        id: "desc",
                                        title: "По названию (убывание)",
                                        type: "name",
                                    },
                                    {
                                        id: "asc",
                                        title: "По цене (возрастание)",
                                        type: "price",
                                    },
                                    {
                                        id: "desc",
                                        title: "По цене (убывание)",
                                        type: "price",
                                    },
                                ].map((item) => (
                                    <li key={item.id + item.type}>
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSorting(item);
                                            }}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Контент */}
                    {thirdStageActive && selectedItem ? (
                        <ThirdStage
                            item={selectedItem}
                            onClose={resetStateToDefault}
                            selectedUser={currentUser}
                        />
                    ) : !selectedItem ? (
                        <ShopList
                            shopList={filtredItems}
                            handleItemSelect={handleItemSelect}
                        />
                    ) : (
                        <SecondStage
                            item={selectedItem}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                            onReturnBtnClick={() => setSelectedItem(null)}
                            handleConfirm={handleConfirm}
                            userCanBeChanged={true}
                            isCommentForUserRequired={
                                selectedUser?.id !== currentUser?.id
                            }
                        />
                    )}
                </div>
            </div>

            {/* Мобильная версия */}
            <div className="d-md-none">
                <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column bg-white align-items-start gap-3 ps-2 pb-2">
                        <button
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => setIsMenuOpen(true)}
                        >
                            Выбрать категорию товаров
                        </button>
                        <div className="d-flex gap-4 align-items-center justify-content-between w-100 pe-4">
                            <div className="bg-gray-3 p-2">
                                <div className="form-check form-check-reverse form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckDefault"
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexSwitchCheckDefault"
                                        checked={showAccessibleOnly}
                                        onChange={() =>
                                            setShowAccessibleOnly(
                                                !showAccessibleOnly
                                            )
                                        }
                                    >
                                        Доступные мне
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex gap-2 align-items-center">
                                <MeowSVG />
                                {currentUser?.meow}
                            </div>
                        </div>
                        <div className="dropdown">
                            <button
                                className="btn text-muted text-nowrap p-0 border-0"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Сортировка:{" "}
                                <span className="text-lowercase">
                                    {sorting.title || "Без сортировки"}
                                </span>
                            </button>
                            <div className="dropdown-menu pe-5">
                                {[
                                    {
                                        id: "asc",
                                        title: "По названию (возрастание)",
                                        type: "name",
                                    },
                                    {
                                        id: "desc",
                                        title: "По названию (убывание)",
                                        type: "name",
                                    },
                                    {
                                        id: "asc",
                                        title: "По цене (возрастание)",
                                        type: "price",
                                    },
                                    {
                                        id: "desc",
                                        title: "По цене (убывание)",
                                        type: "price",
                                    },
                                ].map((item) => (
                                    <li key={item.id + item.type}>
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSorting(item);
                                            }}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Контент */}
                    {thirdStageActive && selectedItem ? (
                        <ThirdStage
                            item={selectedItem}
                            onClose={resetStateToDefault}
                            selectedUser={currentUser}
                        />
                    ) : !selectedItem ? (
                        <ShopList
                            shopList={filtredItems}
                            handleItemSelect={handleItemSelect}
                        />
                    ) : (
                        <SecondStage
                            item={selectedItem}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                            onReturnBtnClick={() => setSelectedItem(null)}
                            handleConfirm={handleConfirm}
                            userCanBeChanged={true}
                            isCommentForUserRequired={
                                selectedUser?.id !== currentUser?.id
                            }
                            isMobile={true}
                        />
                    )}
                </div>
                {/* Мобильное меню категорий */}

                <BurgerMenu
                    MenuContent={ShopMobileMenuContent}
                    showToggleButton={false}
                    isOpen={isMenuOpen}
                    id="shop-mobile-menu"
                    onClose={() => setIsMenuOpen(false)}
                />
            </div>
        </div>
    );
};

export default ShopWrapper;
