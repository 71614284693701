import { useDispatch } from "react-redux";
import { setFilter } from "../redux/shopSlice";

const categories = [
    { id: 0, href: "#all", img: "menu.svg", label: "Все товары" },
    { id: 7, href: "#priceless", img: "sparks.svg", label: "Бесценное" },
    { id: 5, href: "#goodies", img: "pizza-slice.svg", label: "Вкусняшки" },
    { id: 6, href: "#entertainment", img: "gift.svg", label: "Развлечения" },
    {
        id: 1,
        href: "#certificates",
        img: "page-star.svg",
        label: "Сертификаты",
    },
    { id: 3, href: "#work", img: "attachment.svg", label: "Мелочи для работы" },
    { id: 4, href: "#charity", img: "heart.svg", label: "Благотворительность" },
    {
        id: 2,
        href: "#brand",
        img: "barcode.svg",
        label: "Брендированные товары",
    },
];

const ShopMobileMenuContent = () => {
    const dispatch = useDispatch();
    return (
        <ul className="pt-4 mt-2 row bg-white rounded nav nav-tabs d-flex align-items-center pe-3 gap-1 border-0">
            {categories.map(({ id, href, img, label }, index) => (
                <li key={id} className="nav-item w-90 mb-1">
                    <div
                        className={`btn border-0 btn-light d-flex align-items-center justify-content-start p-3 ${
                            index === 0 ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        data-category-id={id}
                        href={href}
                        onClick={() => dispatch(setFilter(id))}
                    >
                        <img
                            src={`/img/meow/${img}`}
                            className="pe-3"
                            alt={label}
                        />
                        <span className="small">{label}</span>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default ShopMobileMenuContent;
