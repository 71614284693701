import { useState } from "react";
import { useSelector } from "react-redux";
import TextHiddenIconSVG from "../Icons/TextHiddenIconSVG";

const ItemListInModal = ({
    itemsSelector,
    ItemCard,
    onItemClick = () => {},
    isSecondStepRequired = false,
    onActionClick = () => {},
    userId,
    action,
}) => {
    const items = useSelector(itemsSelector);
    const [selectedItem, setSelectedItem] = useState(null);

    const [isCommentFieldVisible, setIsCommentFieldVisible] = useState(false);
    const [comment, setComment] = useState("");
    const [isMedalTextHidden, setIsMedalTextHidden] = useState(false);

    const handleItemSelect = (item) => {
        setSelectedItem(item); // Сохраняем весь объект
        onItemClick(item);
        if (isSecondStepRequired) {
            setIsCommentFieldVisible(true);
        }
    };

    const handleActionClick = () => {
        console.log("Action clicked", action);

        onActionClick({
            action,
            id: selectedItem?.id,
            commentForUser: comment,
            userId,
            isMedalTextHidden,
        });
    };

    return (
        <div className="d-flex flex-wrap">
            {items?.length > 0 &&
                !isCommentFieldVisible &&
                items.map((item) => (
                    <ItemCard
                        key={item.id}
                        item={item}
                        onItemClick={handleItemSelect}
                        selectedItemId={selectedItem?.id} // Передаем id выбранного элемента
                    />
                ))}
            {isSecondStepRequired && isCommentFieldVisible && (
                <div className="d-flex flex-column gap-2 w-100">
                    {selectedItem && (
                        <div className="d-flex gap-2 align-items-end justify-content-between">
                            <ItemCard
                                key={selectedItem.id}
                                item={selectedItem}
                                onItemClick={() => {}} // Убираем кликабельность
                                selectedItemId={selectedItem.id} // Оставляем выделение
                            />
                            {action === "reward" && (
                                <button
                                    className={
                                        "btn btn-sm my-2 p-1 p-sm-2 " +
                                        (isMedalTextHidden
                                            ? "btn-primary"
                                            : "btn-gray-6-bg")
                                    }
                                    onClick={() =>
                                        setIsMedalTextHidden(!isMedalTextHidden)
                                    }
                                >
                                    <TextHiddenIconSVG color={"#fff"} />
                                    <span className="ms-2 text-white">
                                        Скрыть текст медали
                                    </span>
                                </button>
                            )}
                        </div>
                    )}
                    <textarea
                        className="form-control w-100"
                        placeholder="Введите комментарий"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <button
                        className="btn btn-primary w-100"
                        disabled={!comment.length}
                        onClick={handleActionClick}
                    >
                        Подтвердить
                    </button>
                </div>
            )}
        </div>
    );
};

export default ItemListInModal;
