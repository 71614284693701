const BalanceIconSVG = ({ size = 32 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.6002 12.5368C26.7003 13.0868 27 13.6617 27 13.9993C27 14.337 26.7003 14.9119 25.6002 15.4619C24.5599 15.9821 23.0527 16.3327 21.3333 16.3327C19.614 16.3327 18.1068 15.9821 17.0665 15.4619C15.9664 14.9119 15.6667 14.337 15.6667 13.9993C15.6667 13.6617 15.9664 13.0868 17.0665 12.5368C18.1068 12.0166 19.614 11.666 21.3333 11.666C23.0527 11.666 24.5599 12.0166 25.6002 12.5368ZM26.4946 10.7479C25.122 10.0616 23.2959 9.66602 21.3333 9.66602C19.3708 9.66602 17.5446 10.0616 16.1721 10.7479C14.8593 11.4043 13.6667 12.496 13.6667 13.9993C13.6667 15.5027 14.8593 16.5944 16.1721 17.2508C17.5446 17.9371 19.3708 18.3327 21.3333 18.3327C23.2959 18.3327 25.122 17.9371 26.4946 17.2508C27.8073 16.5944 29 15.5027 29 13.9993C29 12.496 27.8073 11.4043 26.4946 10.7479Z"
                fill="#BF1B2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 18.334C27.4477 18.334 27 18.7817 27 19.334C27 19.6716 26.7003 20.2465 25.6002 20.7966C24.5599 21.3167 23.0527 21.6673 21.3333 21.6673C19.614 21.6673 18.1068 21.3167 17.0665 20.7966C15.9664 20.2465 15.6667 19.6716 15.6667 19.334C15.6667 18.7817 15.219 18.334 14.6667 18.334C14.1144 18.334 13.6667 18.7817 13.6667 19.334C13.6667 20.8373 14.8593 21.9291 16.1721 22.5854C17.5446 23.2717 19.3708 23.6673 21.3333 23.6673C23.2959 23.6673 25.122 23.2717 26.4946 22.5854C27.8073 21.9291 29 20.8373 29 19.334C29 18.7817 28.5523 18.334 28 18.334Z"
                fill="#BF1B2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.00038 11.666C3.4481 11.666 3.00038 12.1137 3.00038 12.666C3.00038 14.1693 4.19305 15.2611 5.50579 15.9175C6.87834 16.6037 8.70452 16.9993 10.667 16.9993C12.2723 16.9993 13.7785 16.7349 15.0194 16.2688C15.5364 16.0746 15.798 15.498 15.6038 14.981C15.4096 14.464 14.833 14.2023 14.316 14.3966C13.3282 14.7676 12.064 14.9993 10.667 14.9993C8.94768 14.9993 7.44053 14.6488 6.40022 14.1286C5.30009 13.5785 5.00038 13.0037 5.00038 12.666C5.00038 12.1137 4.55267 11.666 4.00038 11.666Z"
                fill="#BF1B2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.00005 16.334C3.44777 16.334 3.00005 16.7817 3.00005 17.334C3.00005 18.8373 4.19273 19.9291 5.50546 20.5854C6.87801 21.2717 8.70419 21.6673 10.6667 21.6673C12.2716 21.6673 13.7776 21.403 15.0183 20.9371C15.5353 20.7429 15.7971 20.1663 15.6029 19.6493C15.4087 19.1323 14.8322 18.8706 14.3151 19.0647C13.3274 19.4357 12.0634 19.6673 10.6667 19.6673C8.94736 19.6673 7.4402 19.3167 6.39989 18.7966C5.29976 18.2465 5.00005 17.6716 5.00005 17.334C5.00005 16.7817 4.55234 16.334 4.00005 16.334Z"
                fill="#BF1B2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.00005 6.33398C3.44777 6.33398 3.00005 6.7817 3.00005 7.33398V22.0007C3.00005 23.504 4.19273 24.5957 5.50546 25.2521C6.87801 25.9384 8.70419 26.334 10.6667 26.334C12.2718 26.334 13.7777 26.0694 15.0183 25.6035C15.5353 25.4093 15.7971 24.8327 15.6029 24.3157C15.4087 23.7987 14.8321 23.537 14.3151 23.7312C13.3273 24.1022 12.0633 24.334 10.6667 24.334C8.94736 24.334 7.4402 23.9834 6.39989 23.4632C5.29976 22.9132 5.00005 22.3383 5.00005 22.0007V7.33398C5.00005 6.7817 4.55234 6.33398 4.00005 6.33398Z"
                fill="#BF1B2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.333 6.33398C16.7807 6.33398 16.333 6.7817 16.333 7.33398V11.334C16.333 11.8863 16.7807 12.334 17.333 12.334C17.8853 12.334 18.333 11.8863 18.333 11.334V7.33398C18.333 6.7817 17.8853 6.33398 17.333 6.33398Z"
                fill="#BF1B2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 13C27.4477 13 27 13.4477 27 14V24.6667C27 25.0043 26.7003 25.5792 25.6002 26.1293C24.5599 26.6494 23.0527 27 21.3333 27C19.614 27 18.1068 26.6494 17.0665 26.1293C15.9664 25.5792 15.6667 25.0043 15.6667 24.6667V14C15.6667 13.4477 15.219 13 14.6667 13C14.1144 13 13.6667 13.4477 13.6667 14V24.6667C13.6667 26.17 14.8593 27.2617 16.1721 27.9181C17.5446 28.6044 19.3708 29 21.3333 29C23.2959 29 25.122 28.6044 26.4946 27.9181C27.8073 27.2617 29 26.17 29 24.6667V14C29 13.4477 28.5523 13 28 13Z"
                fill="#BF1B2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9332 5.87074C16.0333 6.4208 16.333 6.99569 16.333 7.33333C16.333 7.67098 16.0333 8.24587 14.9332 8.79593C13.8929 9.31608 12.3857 9.66667 10.6663 9.66667C8.94698 9.66667 7.43982 9.31608 6.39951 8.79593C5.29939 8.24587 4.99968 7.67098 4.99968 7.33333C4.99968 6.99569 5.29939 6.4208 6.39951 5.87074C7.43982 5.35058 8.94698 5 10.6663 5C12.3857 5 13.8929 5.35058 14.9332 5.87074ZM15.8276 4.08188C14.4551 3.39561 12.6289 3 10.6663 3C8.70381 3 6.87763 3.39561 5.50508 4.08188C4.19235 4.73825 2.99968 5.83003 2.99968 7.33333C2.99968 8.83664 4.19235 9.92842 5.50508 10.5848C6.87763 11.2711 8.70381 11.6667 10.6663 11.6667C12.6289 11.6667 14.4551 11.2711 15.8276 10.5848C17.1403 9.92842 18.333 8.83664 18.333 7.33333C18.333 5.83003 17.1403 4.73825 15.8276 4.08188Z"
                fill="#BF1B2C"
            />
        </svg>
    );
};

export default BalanceIconSVG;
