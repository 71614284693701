import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import animationData from "./gift-send-rocket.json";
import MeowSVG from "../../Icons/MeowSVG";
import { useSelector } from "react-redux";

const ThirdStage = ({ item, selectedUser = {}, onClose = () => {} }) => {
    // console.log("item", item);
    const { currentUser } = useSelector((state) => state.users);
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCount) => prevCount - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (countdown === 0) {
            onClose(true);
        }
    }, [countdown, onClose]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <div className="d-flex flex-column gap-4">
            {currentUser.id === selectedUser.id ? (
                <>
                    <div>
                        <h4 className="fw-medium">
                            Товар {item.name} был успешно куплен
                        </h4>
                    </div>
                </>
            ) : (
                <div>
                    <h4 className="fw-medium">
                        Подарок для{" "}
                        <span className="text-primary">
                            {selectedUser.name}
                        </span>{" "}
                        успешно отправлен!
                    </h4>
                    <div>
                        <span>
                            Получатель может забрать подарок в удобное для него
                            время
                        </span>
                    </div>
                </div>
            )}
            <div className="d-flex align-items-center gap-1">
                <span>У вас было списано {item.summ * item.quantity}</span>
                <MeowSVG />
            </div>
            <div className="d-flex justify-content-end align-items-center gap-1">
                <span>Окно закроется через {countdown} секунд</span>
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => onClose(true)}
                >
                    Закрыть окно
                </button>
            </div>
            <div className="animation">
                <Lottie options={defaultOptions} height={400} width={400} />
            </div>
        </div>
    );
};

export default ThirdStage;
