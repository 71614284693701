// это просто заготовка для дальнейшей работы, пока используем только фильтр категорий

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    loading: false,
    error: null,
    cart: [],
    totalAmount: 0,
    totalQuantity: 0,
    filter: null,
};

const shopSlice = createSlice({
    name: "shop",
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        addToCart: (state, action) => {
            const newItem = action.payload;
            const existingItem = state.cart.find(
                (item) => item.id === newItem.id
            );

            if (existingItem) {
                existingItem.quantity++;
                existingItem.totalPrice =
                    existingItem.quantity * existingItem.price;
            } else {
                state.cart.push({
                    ...newItem,
                    quantity: 1,
                    totalPrice: newItem.price,
                });
            }

            state.totalQuantity++;
            state.totalAmount = state.cart.reduce(
                (total, item) => total + item.totalPrice,
                0
            );
        },
        removeFromCart: (state, action) => {
            const id = action.payload;
            const existingItem = state.cart.find((item) => item.id === id);

            if (existingItem.quantity === 1) {
                state.cart = state.cart.filter((item) => item.id !== id);
            } else {
                existingItem.quantity--;
                existingItem.totalPrice =
                    existingItem.quantity * existingItem.price;
            }

            state.totalQuantity--;
            state.totalAmount = state.cart.reduce(
                (total, item) => total + item.totalPrice,
                0
            );
        },
        clearCart: (state) => {
            state.cart = [];
            state.totalQuantity = 0;
            state.totalAmount = 0;
        },
        setFilter: (state, action) => {
            // console.log("setFilter", action.payload);

            state.filter = action.payload;
        },
    },
});

export const {
    setProducts,
    setLoading,
    setError,
    addToCart,
    removeFromCart,
    clearCart,
    setFilter,
} = shopSlice.actions;

export default shopSlice.reducer;
