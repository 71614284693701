const userId = document.getElementById("user-data")?.dataset.userId;

const roleId = document.getElementById("user-data")?.dataset.roleId * 1;

console.log(userId, roleId);

export const links = {
    profile: [
        { id: 1, name: "Профиль", link: "/profile" },
        {
            id: 2,
            name: "Настройки",
            link: userId ? `/users/edit/${userId}` : "/login",
        },
        { id: 3, name: "Альбомы", link: "/albums" },
        { id: 4, name: "Транзакции", link: "/user/my/transactions" },
    ],
    notifications: [
        { id: 1, name: "Акции", link: "/stocks" },
        { id: 2, name: "Дайджесты", link: "/digests" },
        { id: 3, name: "Приказы", link: "/orders" },
        { id: 4, name: "Фотографии", link: "/albums/photos/all" },
        { id: 5, name: "Опросы", link: "https://opros.carolinaspb.ru/" },
    ],
    education: [{ id: 1, name: "Библиотека", link: "/library" }],
    employeeServices: [
        { id: 1, name: "Компания", link: "/users" },
        { id: 2, name: "МяуМаркет", link: "/shop" },
        { id: 3, name: "Видеоконференция", link: "/conference" },
    ],
};

// ✅ Если `roleId === 1`, добавляем "Админ-часть" на 4-е место
if (roleId === 1) {
    links.profile.splice(4, 0, {
        id: 5,
        name: "Админ-часть",
        link: "/admin",
    });
}

// ✅ Добавляем кнопку "Выход" в конец массива
links.profile.push({
    id: 6,
    name: "Выход",
    onclicks:
        "event.preventDefault(); document.getElementById('logout-form').submit();",
});
