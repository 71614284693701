import { combineReducers } from "redux";
import usersReducer from "./usersSlice";
import stenasReducer from "./stenasSlice";
import extraTypesReducer from "./extraTypesSlice";
import postFieldReducer from "./postFieldSlice";
import shopSLice from "./shopSlice";

const rootReducer = combineReducers({
    users: usersReducer,
    stenas: stenasReducer,
    extraTypes: extraTypesReducer,
    postField: postFieldReducer,
    shop: shopSLice,
});

export default rootReducer;
